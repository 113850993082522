import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .myDataGrid {
    min-height: 300px;
    max-height: 320px;
  }

  .containerTab {
    .nav a{
      height: 50px;
    }
  }


  .ContainerStatusPdv{
    max-width: 310px;

    @media screen and (max-width: 1199px){
      max-width: 50%
    }
  }

  .ContainerFinalizadora{
    @media screen and (max-width: 1199px){
      width:40%
    }
  }

  .ContainerLimiteCredito{
    max-width: 200px;
    .actButtonsCredito{
      .confirm {
        min-width:80px !important;
      }
      .cancel{
        min-width:80px !important;
      }
    }

    @media screen and (max-width: 1199px){
      min-width: 100% !important;
    }

  }


  .containerButtonFamilia{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .containerCodigo{
    max-width: 250px;
    padding-right: 3px !important;

    @media screen and (max-width: 1199px){
      max-width: 206px;
    }
    @media screen and (max-width: 991px){
      max-width: 140px;
    }
    @media screen and (max-width: 767px){
      max-width: 100%;
    }

  }

  .btnLimiteCredito {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCredito:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }

  .actButtonsCredito {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    @media screen and (min-width: 1000px){
      button {
        width: 100%;
      }
    }

    @media screen and (max-width: 1000px){
      .confirm {
          min-width:130px !important;
          height: 42px;
        }
        .cancel{
          min-width:130px !important;
          height: 42px;
        }
    }

    @media screen and (max-width: 991px){
      margin-right: 0px !important
      .confirm {
          min-width:100px !important;
        }
        .cancel{
          min-width:150px !important;
        }
    }
  }
  .inputDiasContainer{
    input {
      margin-top: 2px;
      height: '40px';
    }
  }

  .tipoCondicao {
    max-width: 300px;
  }

  @media screen and (max-width: 1199px){
    .tipoCondicao {
        max-width: 230px;
    }

    .buttonContainer{
      .confirm{
        min-width: 130px;
      }
      .cancel{
        min-width: 130px;
      }
    }
  }

  @media screen and (max-width: 991px){
    .buttonContainer{
      min-width: 100%;
    }
    .tipoCondicao {
      width: 40%;
    }
    .finalizadoraContainer{
      width: 41%;
    }
  }

  @media screen and (max-width: 767px) {
    .buttonContainer{
      width: 100%;
    }
    .tipoCondicao {
      max-width: 100%;
      width: 100%;
    }
    .finalizadoraContainer{
      width: 100%;
    }
  }
  button:focus {
    outline: 0;
  }
  .btn-outline-danger:hover {
    background-color: #d94848;
    color: #fff;
  }
  .inputButton {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -40px;
    align-items: center;
    justify-content: center;
  }
  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      margin-bottom: 0.0938rem;
      height: 2.425rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 37px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    .form-control {
      padding: 0.375rem 0.75rem;
    }

    .form-control.is-invalid {
      background-image: none;
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
      border-bottom: 2px solid #cf1919 !important ;
      &:focus {
        box-shadow: none;
      }
      padding: -0.375rem -0.75rem !important;
    }
  }

  .selectContainer {
    .form-group {
      margin-left: -8px;
    }
  }

  .MuiDataGrid-columnHeader--moving {
    background-color: #8850bf !important;

    .MuiDataGrid-columnSeparator{
      display:none;
    }
  }

  .actButtonsAdmin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media screen and (min-width: 1000px){
      button {
        width: 55%;
      }
  }

`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;

export const Label = styled.div`
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2px;
  margin-top: 10px;
  margin-left: 0;
`;

export const Separator = styled.div`
  margin-top: 10px;
  padding: 0;

  p {
    color: #bdbdbd;
    width: 100%;
    text-align: start;
    border-bottom: 1px solid #ededed;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  p span {
    background: #fff;
    padding: 0 10px;
  }
`;

export const InpuTypeDate = styled.input`
  height: 40px;
  margin-top: 30px;
  background-color: #fafbfc;
  border: 2px solid;
  border-color: #dfe1e6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  /* outline: none; */
  width: 100%;
  line-height: 1.4285714285714286;
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const FildsetContainer = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  margin-top: -10px;
  padding: 16px;
  border: 1px solid #dfe1e6;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const Legend = styled.legend`
  position: absolute;
  font-size: 14px;
  margin-top: -28px;
  background: #fff;
  width: auto;
  margin-left: 10px;
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;

