import React, { useCallback, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Row, Col } from 'react-bootstrap';
import Separator from '~/components/Separator';
import { InputMaskCpf, InputSelect, InputText } from '~/components/NovosInputs';
import Button from '@atlaskit/button';
import { CircularProgress } from '@material-ui/core';
import { AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai';
import { CancelButton, ConfirmButton } from '~/components/Buttons';
import { useClienteContext } from '../../ClienteContext';
import { parentescos } from '../../defaultData';
import { GoPencil } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import { deleteItens } from '~/utils/masterDetail';
import { ButtonRow } from '../../styles';

export const AbaFamiliaAutorizados: React.FC = () => {
  const [initParentesco, setInitParentesco] = useState<boolean>(false);

  const {
    loadingIcon,
    autorizados,
    autorizadoRegister,
    autorizadoControl,
    setValueAutorizado,
    formStateAutorizado,
    searchPessoaAutorizado,
    handleClearAutorizado,
    handleAutorizado,
    masterDetail,
    setMasterDetail,
    setAutorizadoAEditar,
    setCodigoPessoaAutorizada,
    setAutorizados,
  } = useClienteContext();

  const colunasAutorizados = [
    {
      field: 'uuid',
      headerName: 'Id',
      hide: true,
      width: 90,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'des_tipo_parentesco',
      headerName: 'Parentesco',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'nome_autorizado',
      headerName: 'Nome',
      width: 340,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'cod_autorizado',
      headerName: 'Código',
      width: 150,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'num_documento',
      headerName: 'Documento',
      width: 230,
      sortable: false,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ações',
      width: 180,
      sortable: false,
      headerClassName: 'super-app-theme--header',
      // disableClickEventBubbling: true,
      renderCell: (params: { row: any }) => {
        const onEdit = () => {
          const { row } = params;

          setValueAutorizado('parentesco', {
            value: row.tipo_parentesco,
            label: row.des_tipo_parentesco,
          });
          setValueAutorizado('cpfAutorizado', row.num_documento);
          setValueAutorizado('nomeAutorizado', row.nome_autorizado);
          setValueAutorizado('codigoAutorizado', row.cod_autorizado);

          setCodigoPessoaAutorizada(row.cod_pessoa_autorizado);
          setAutorizadoAEditar({
            uuid: row.uuid,
            cod_cliente: row.cod_cliente,
          });
          const elm = document.getElementById('collapseTwo');
          if (elm) {
            elm.removeAttribute('class');
            elm.classList.add('accordion-collapse', 'collapse', 'show');
          }
        };
        const onDelete = () => {
          const { row } = params;
          handleRemovellAutorizado(row.uuid);
        };

        return (
          <>
            <ButtonRow type="button" onClick={onEdit}>
              <GoPencil size={20} style={{ color: '#72ab90' }} /> Editar
            </ButtonRow>
            <ButtonRow type="button" onClick={onDelete}>
              <MdDeleteForever size={20} style={{ color: '#e63c3c' }} /> Excluir
            </ButtonRow>
          </>
        );
      },
    },
  ];

  const handleRemovellAutorizado = useCallback(
    async (uuid) => {
      const autorizadosDetail: any[] = await deleteItens(
        'autorizados',
        uuid,
        masterDetail,
        setMasterDetail,
      );

      setAutorizados(autorizadosDetail);
      handleClearAutorizado();
    },
    [handleClearAutorizado, masterDetail],
  );

  return (
    <div className="row">
      <Col sm={12}>
        <Separator labelText="Família/Autorizados" childrenWidth="200px">
          <button
            type="button"
            className="btnLimiteCredito"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
            style={{
              marginTop: '-7px',
              backgroundColor: '#F4F5F7',
            }}
          >
            <AiOutlinePlusCircle
              size={16}
              style={{
                marginTop: '-2px',
                color: 'green',
                marginRight: '3px',
              }}
            />
            Adicionar Novo Autorizado
          </button>
        </Separator>
      </Col>
      {/* Inicio accordion */}
      <div
        className="accordion"
        id="accordionExample"
        style={{ padding: '0px' }}
      >
        <div className="accordion-item">
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <Row>
                <Col sm={12} md={3} lg={3} xl={3}>
                  <div className="selectContainer">
                    <InputSelect
                      label="Parentesco"
                      placeholder="Selecione"
                      name="parentesco"
                      register={autorizadoRegister}
                      isError={
                        initParentesco &&
                        !!formStateAutorizado.errors.parentesco
                      }
                      control={autorizadoControl}
                      options={parentescos}
                      changeSelected={(selected) => {
                        setInitParentesco(false);
                        setValueAutorizado('parentesco', selected);
                      }}
                    />
                  </div>
                </Col>
                <Col sm={12} md={3}>
                  <div className="input-container">
                    <InputMaskCpf
                      label="CPF"
                      name="cpfAutorizado"
                      isError={!!formStateAutorizado.errors.cpfAutorizado}
                      control={autorizadoControl}
                      register={autorizadoRegister}
                    />
                    <Button
                      type="button"
                      className="min-button"
                      onClick={searchPessoaAutorizado}
                    >
                      {loadingIcon === 'cpfAutorizado' ? (
                        <CircularProgress style={{ color: '#fff' }} size={15} />
                      ) : (
                        <AiOutlineSearch color="#fff" />
                      )}
                    </Button>
                  </div>
                </Col>
                <Col sm={12} md={3}>
                  <InputText
                    label="Nome"
                    maxLength={50}
                    placeholder="Informe o Nome"
                    control={autorizadoControl}
                    caseInput="upper"
                    name="nomeAutorizado"
                    register={autorizadoRegister}
                    disabled={false}
                    isError={!!formStateAutorizado.errors.nomeAutorizado}
                  />
                </Col>
                <Col sm={12} md={4} className="containerCodigo">
                  <InputText
                    label="Código"
                    maxLength={20}
                    placeholder="Informe o Código"
                    name="codigoAutorizado"
                    register={autorizadoRegister}
                    disabled={false}
                    isError={!!formStateAutorizado.errors.codigoAutorizado}
                  />
                </Col>
                <Col sm={12} md={3} className="containerButtonFamilia">
                  <div
                    className="actButtonsCredito"
                    style={{ marginRight: '-8px' }}
                  >
                    <ConfirmButton
                      onClick={() => {
                        setInitParentesco(true);
                        handleAutorizado();
                      }}
                    >
                      Confirmar
                    </ConfirmButton>
                    <CancelButton onClick={handleClearAutorizado}>
                      Cancelar
                    </CancelButton>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      {/* fim accordion */}
      <Col
        sm={12}
        className="dataTable"
        style={{
          width: '100%',
          marginTop: '15px',
        }}
      >
        <Col
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              className="myDataGrid"
              rows={autorizados}
              columns={colunasAutorizados}
              pageSize={4}
              autoHeight
              columnBuffer={8}
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              localeText={{
                noRowsLabel: 'Nenhum registro encontrado',
                columnMenuLabel: 'Menu',
                columnMenuFilter: 'Filtrar',
                columnMenuHideColumn: 'Esconder',
                columnMenuUnsort: 'Não ordenar',
                columnMenuSortAsc: 'Ordernar ASC',
                columnMenuSortDesc: 'Ordernar DESC',
                columnMenuShowColumns: 'Mostrar colunas',
              }}
            />
          </div>
        </Col>
      </Col>
    </div>
  );
};
