import Button from '@atlaskit/button';
import Select from '@atlaskit/select';
import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { AiOutlineGlobal, AiOutlineSearch } from 'react-icons/ai';
import { FaWhatsapp } from 'react-icons/fa';
import {
  InputCidade,
  InputDate,
  InputMaskCelular,
  InputMaskCep,
  InputMaskCnpj,
  InputMaskCpf,
  InputMaskRg,
  InputMaskTelefone,
  InputSelect,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';

import { useClienteContext } from '../../ClienteContext';
import { defaultOptions } from '../../defaultData';
import { SelectType } from '~/components/NovosInputs/InputCidade/protocols';

export const AbaGeral: React.FC = () => {
  const [inputSite, setInputSite] = useState<string>('');
  const [cidadeSelected, setCidadeSelected] = useState<SelectType>();

  const inputCidadeRef = useRef<HTMLDivElement | null>(null);

  const {
    tipoPessoa,
    loadingIcon,
    flagInativo,
    setFlagInativo,
    classificacao,
    setClassificacao,
    produtorRural,
    register,
    control,
    setValue,
    formState: { errors },
    cidade,
    searchEmpresa,
    searchPessoa,
    searchCep,
    classificacaoOptions,
    openWhatsApp,
    openSite,
    clearErrors,
    clearCidade,
  } = useClienteContext();

  useEffect(() => {
    if (inputCidadeRef.current) {
      const inputCidade: HTMLInputElement | null =
        inputCidadeRef.current.querySelector('[name="cidades"]');

      if (inputCidade && cidadeSelected) {
        const { label } = cidadeSelected;
        const { value } = inputCidade;

        if (value !== label) inputCidade.value = label;
      }
    }
  }, [cidadeSelected]);

  return (
    <div className="row">
      <Col sm={12}>
        <Separator labelText="Dados Pessoais" />
      </Col>
      <Col sm={12} md={3}>
        <div className="input-container">
          {tipoPessoa === 0 ? (
            <InputMaskCpf
              label="CPF"
              name="num_cpf"
              isError={!!errors.num_cpf}
              control={control}
              clickedButton={searchPessoa}
              loadingIcon={loadingIcon === 'buscaPessoa'}
              hasButton
              register={register}
            />
          ) : (
            <InputMaskCnpj
              label="CNPJ"
              name="num_cnpj"
              isError={!!errors.num_cnpj}
              clickedButton={searchEmpresa}
              control={control}
              loadingIcon={loadingIcon === 'buscaPessoa'}
              hasButton
            />
          )}
        </div>
      </Col>

      {tipoPessoa === 0 && (
        <Col sm={12} md={2}>
          <InputMaskRg
            label="RG"
            name="num_rg_ie"
            isError={!!errors.num_rg_ie}
            control={control}
          />
        </Col>
      )}

      {(tipoPessoa === 1 || produtorRural) && (
        <Col sm={12} md={2}>
          <InputText
            label="IE"
            maxLength={100}
            toLowerCase={false}
            name="num_ie"
            register={register}
            isError={!!errors.num_ie}
          />
        </Col>
      )}

      {tipoPessoa === 0 && (
        <Col sm={12} md={2}>
          <InputSelect
            label="Sexo *"
            placeholder="Selecione"
            name="sexo"
            register={register}
            isError={!!errors.sexo}
            control={control}
            options={defaultOptions.sexo}
            changeSelected={(selected) => {
              clearErrors('sexo');
              setValue('sexo', selected);
            }}
          />
        </Col>
      )}
      <Col sm={12} md={2} />
      <Col sm={12} md={6}>
        <InputText
          label={tipoPessoa === 0 ? 'Nome *' : 'Razão Social *'}
          maxLength={100}
          toLowerCase={false}
          placeholder={
            tipoPessoa === 0 ? 'Informe o Nome' : 'Informe a Razão Social'
          }
          name="name"
          register={register}
          disabled={false}
          isError={!!errors.name}
        />
      </Col>
      <Col sm={12} md={4}>
        <InputText
          label={tipoPessoa === 0 ? 'Apelido' : 'Nome Fantasia'}
          maxLength={50}
          toLowerCase={false}
          placeholder={
            tipoPessoa === 0 ? 'Informe o Apelido' : 'Informe o Nome Fantasia'
          }
          name="apelido"
          register={register}
          disabled={false}
          isError={!!errors.apelido}
        />
      </Col>
      <Col sm={12} md={2}>
        <InputDate
          label={tipoPessoa === 0 ? 'Nascimento' : 'Abertura'}
          placeholder=""
          name="nascimentoAbertura"
          register={register}
          isError={!!errors.nascimentoAbertura}
          control={control}
        />
      </Col>
      <Col sm={12}>
        <Separator labelText="Contato" />
      </Col>
      <Col sm={12} md={2}>
        <InputMaskTelefone
          label="Telefone"
          name="telefone"
          isError={!!errors.telefone}
          control={control}
        />
      </Col>
      <Col sm={12} md={2}>
        <div className="input-container">
          <InputMaskCelular
            label="Celular"
            name="celular"
            isError={!!errors.celular}
            control={control}
          />
          <Button type="button" className="min-button" onClick={openWhatsApp}>
            <FaWhatsapp color="#fff" style={{ marginTop: '-2px' }} />
          </Button>
        </div>
      </Col>
      <Col sm={12} md={8}>
        <InputText
          label="Contato"
          maxLength={50}
          placeholder="Informe o Contato"
          name="contato"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.contato}
        />
      </Col>
      <Col sm={12} md={6}>
        <InputText
          label="E-mail"
          maxLength={100}
          placeholder="Informe o E-mail"
          name="email"
          toLowerCase
          register={register}
          disabled={false}
          isError={!!errors.email}
        />
      </Col>
      <Col sm={12} md={6}>
        <div className="input-container">
          <InputText
            label="Site"
            maxLength={150}
            placeholder="Informe a página WEB"
            name="site"
            toLowerCase
            register={register}
            disabled={false}
            isError={!!errors.site}
            onChange={(e: any) => {
              setInputSite(e.target.value);
            }}
          />
          <Button
            type="button"
            className="min-button"
            onClick={() => openSite(inputSite)}
          >
            <AiOutlineGlobal color="#fff" style={{ marginTop: '-2px' }} />
          </Button>
        </div>
      </Col>
      <Col sm={12}>
        <Separator labelText="Endereço" />
      </Col>
      <Col sm={12} md={2}>
        <div className="input-container">
          <InputMaskCep
            label="CEP *"
            name="cep"
            isError={!!errors.cep}
            control={control}
          />
          <Button type="button" className="min-button" onClick={searchCep}>
            {loadingIcon === 'cep' ? (
              <CircularProgress style={{ color: '#fff' }} size={15} />
            ) : (
              <AiOutlineSearch color="#fff" />
            )}
          </Button>
        </div>
      </Col>
      <Col sm={12} md={5}>
        <InputText
          label="Logradouro *"
          maxLength={100}
          placeholder="Informe o Logradouro"
          name="logradouro"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.logradouro}
        />
      </Col>
      <Col sm={12} md={2}>
        <InputText
          label="Número *"
          maxLength={15}
          placeholder="Informe o Número"
          name="numero"
          register={register}
          disabled={false}
          isError={!!errors.numero}
        />
      </Col>
      <Col sm={12} md={3}>
        <InputText
          label="Bairro *"
          maxLength={100}
          placeholder="Informe o Bairro"
          name="bairro"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.bairro}
        />
      </Col>
      <Col sm={12} md={8}>
        <InputText
          label="Complemento"
          maxLength={50}
          placeholder="Informe o Complemento"
          name="complemento"
          toLowerCase={false}
          register={register}
          disabled={false}
          isError={!!errors.complemento}
        />
      </Col>
      <Col sm={12} md={4} ref={inputCidadeRef}>
        <InputCidade
          maxLength={29}
          setValue={setValue}
          label="Cidade *"
          placeholder="Selecione a Cidade"
          name="cidades"
          cidadeABuscar={cidade}
          register={register}
          clearCampo={clearCidade}
          isError={!!errors.cidades}
          control={control}
          changeSelected={(selected) => {
            setValue('cidades', selected);
            setCidadeSelected(selected);
            clearErrors('cidades');
          }}
        />
      </Col>
      <div className="col-md-12">
        <Separator labelText="Classificação" />
      </div>
      <Select
        options={classificacaoOptions}
        label="Classificação"
        placeholder="Informe uma ou mais classificação"
        value={classificacao}
        isMulti
        onChange={(value) => {
          if (Array.isArray(value) && value.length > 0) {
            setValue('classificacao', value);
            setClassificacao(value);
          } else {
            setClassificacao(null);
          }
        }}
        noOptionsMessage={() => 'Nenhum Registro Encontrado'}
        isRequired
        setInvalid={false}
        iniInicializado={false}
      />
    </div>
  );
};
